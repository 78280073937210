import React, { useRef } from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import styled from "@emotion/styled";
import tw from "twin.macro";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from "react-share";

import { differenceInDays, formatDistance, format } from "date-fns";
import GraphQLErrorList from "../components/Blog/graphql-error-list";
import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import PortableText from "../components/Blog/portableText";
import CallToAction from "../components/Repeating/CTA";
import ButtonSolid from "../components/Button/ButtonSolid";

const StyledContent = styled.div`
  p,
  span,
  li {
    ${tw`md:text-xl`}
  }
  }
`;

export const query = graphql`
  query PodcastTemplateQuery($id: String!) {
    podcast: sanityPodcast(id: { eq: $id }) {
      id
      publishedAt
      title
      slug {
        current
      }
      seoTitle
      metaDescription
      excerpt
      pullQuote
      pullQuoteAuthor
      pullQuoteAuthorTitle
      _rawTranscript(resolveReferences: { maxDepth: 5 })
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
    darkHorseLogo: file(
      relativePath: { eq: "podcast/dark-horse-head-logo.png" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 48)
      }
    }
  }
`;

const PodcastTemplate = (props) => {
  const audioRef = useRef(null);

  const handlePlayAudio = () => {
    audioRef.current.play();
  };

  const { data, errors } = props;
  const podcast = data && data.podcast;
  const site = data && data.site;
  return (
    <Layout>
      {errors && <SearchEngineOptimization title="GraphQL Error" />}
      {podcast && (
        <SearchEngineOptimization
          title={podcast.seoTitle}
          description={podcast.metaDescription}
        />
      )}

      {errors && (
        <div className="container">
          <GraphQLErrorList errors={errors} />
        </div>
      )}

      {podcast && (
        <>
          <section className="bg-primary-900 py-20">
            <div className="container">
              <div className="mx-auto max-w-[720px]">
                <div className="mb-10 flex items-center space-x-3.5">
                  <div className="inline-block rounded-sm border-2 border-primary-500 px-2 text-sm font-medium uppercase text-primary-500">
                    Podcast
                  </div>
                  {podcast.publishedAt && (
                    <div className="text-sm font-normal text-white">
                      {differenceInDays(
                        new Date(podcast.publishedAt),
                        new Date()
                      ) > 3
                        ? formatDistance(
                            new Date(podcast.publishedAt),
                            new Date()
                          )
                        : format(new Date(podcast.publishedAt), "MMMM d, yyyy")}
                    </div>
                  )}
                </div>
                <header className="mb-8 md:mb-10">
                  <h1 className="text-white">{podcast.title}</h1>
                </header>

                <div className="flex items-center space-x-4">
                  <div>
                    <GatsbyImage
                      image={data.darkHorseLogo.childImageSharp.gatsbyImageData}
                      className="z-0 h-12 w-12 rounded-full"
                    />
                  </div>

                  <div>
                    <div className="font-bold text-secondary-50">
                      Dark Horse CPA
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <article className="mb-24 pt-12 md:mb-36 md:pt-20">
            <div className="container">
              <div className="mx-auto max-w-[720px]">
                {podcast.excerpt && (
                  <div className="mb-8 border-b border-black pb-26 text-center">
                    <p className="md:text-xl">{podcast.excerpt}</p>
                    <ButtonSolid
                      onClick={handlePlayAudio}
                      text="Play Podcast"
                    />
                  </div>
                )}

                {podcast.pullQuote && (
                  <div className="mb-26 border-b border-black pb-10 text-center">
                    <div className="mb-10 text-sm font-bold uppercase tracking-wide text-primary-500">
                      Tweet Me
                    </div>
                    <blockquote className="mb-10">
                      <q className="mb-10 block font-heading text-xl font-bold text-primary-900 md:text-3xl">
                        {podcast.pullQuote}
                      </q>
                      <footer>
                        <cite className="not-italic">
                          <div className="font-bold text-primary-900 md:text-xl">
                            {podcast.pullQuoteAuthor}
                          </div>
                          <div>{podcast.pullQuoteAuthorTitle}</div>
                        </cite>
                      </footer>
                    </blockquote>
                    <ButtonSolid
                      href={`https://twitter.com/intent/tweet?url=https%3A%2F%2Fdarkhorsecpa.com%2F%3Fp%3D1971&text=%E2%80%9C${podcast.pullQuote}%E2%80%9D%20-%20${podcast.pullQuoteAuthor}`}
                      outboundLink
                      text="Tweet"
                    />
                  </div>
                )}

                <div>
                  {podcast._rawTranscript && (
                    <>
                      <div className="heading-three mb-10 text-center">
                        Podcast Transcript
                      </div>

                      <StyledContent>
                        <PortableText blocks={podcast._rawTranscript} />
                      </StyledContent>
                    </>
                  )}
                </div>

                <div className="mt-16 flex items-center justify-center space-x-6 md:mt-20">
                  <FacebookShareButton
                    url={`${site.siteMetadata.siteUrl}/${podcast.slug.current}/`}
                    quote={podcast.title}
                    className="no-underline"
                  >
                    <i className="fab fa-facebook-f !flex h-10 w-10 items-center justify-center rounded-full bg-primary-50 text-primary-800 transition-colors duration-300 ease-linear hover:bg-primary-800 hover:text-white"></i>
                  </FacebookShareButton>

                  <LinkedinShareButton
                    url={`${site.siteMetadata.siteUrl}/${podcast.slug.current}/`}
                    title={podcast.title}
                    className="no-underline"
                  >
                    <i className="fab fa-linkedin-in !flex h-10 w-10 items-center justify-center rounded-full bg-primary-50 text-primary-800 transition-colors duration-300 ease-linear hover:bg-primary-800 hover:text-white"></i>
                  </LinkedinShareButton>

                  <TwitterShareButton
                    url={`${site.siteMetadata.siteUrl}/${podcast.slug.current}/`}
                    title={podcast.title}
                    className="no-underline"
                  >
                    <i className="fab fa-twitter !flex h-10 w-10 items-center justify-center rounded-full bg-primary-50 text-primary-800 transition-colors duration-300 ease-linear hover:bg-primary-800 hover:text-white"></i>
                  </TwitterShareButton>
                </div>
              </div>
            </div>

            <div className="fixed bottom-0 z-10 flex w-full justify-center bg-black py-5">
              <audio className="w-full max-w-[720px]" controls ref={audioRef}>
                <source
                  src="https://darkhorse.cpa/wp-content/uploads/ic3r803wcnji5p3wo46ssmsntcb4.mp3"
                  type="audio/mpeg"
                />
                Your browser does not support the audio element.
              </audio>
            </div>
          </article>
          <CallToAction />
        </>
      )}
    </Layout>
  );
};

export default PodcastTemplate;
